@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "DMSansMedium";
  src: local("DMSansMedium"),
    url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CircularStdMedium";
  src: local("CircularStdMedium"),
    url("./assets/fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "CircularStdBook";
  src: local("CircularStdBook"),
    url("./assets/fonts/CircularStd-Book.ttf") format("truetype");
}

.App {
  background: #fffdf8;
  color: #232c3f;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100vw;
  font-family: "DMSansMedium", "CircularStdMedium", "sans-serif";
}

.px-10pct {
  padding-left: 10%;
  padding-right: 10%;
}

.grow {
  transition: 0.25s ease;
}

.grow:hover {
  transform: scale(1.03);
  transition: 0.25s ease;
}

.grow-tiny {
  transition: 0.25s ease;
}

.grow-tiny:hover {
  transform: scale(1.01);
  transition: 0.25s ease;
}

.bottom-fade {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 21%,
    rgba(255, 255, 255, 0) 71%
  );
}

.leading-0 {
  line-height: 0;
}

.mowi-bounce {
  z-index: 10;
  bottom: 0;
  right: -20px;
  animation: mowi-bounce 15s infinite ease;
  animation-delay: 1s;
}

@keyframes mowi-bounce {
  0% {
    bottom: -0px;
  }
  3% {
    bottom: 45px;
  }
  5.6% {
    bottom: 0;
  }
  8% {
    bottom: 45px;
  }
  10.6% {
    bottom: 0;
  }
  100% {
    bottom: 0;
  }
}

.width-inherit {
  width: inherit;
}

.safari-responsive {
  min-height: -webkit-fill-available;
}

.horizontal-slider {
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid white;
  color: #fdc02e;
  border-radius: 100%;
  display: block;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.example-track {
  position: relative;
  background: #f7f7f7;
}
.example-track.example-track-0 {
  background: #fdc02e;
}
.horizontal-slider .example-track {
  top: 0;
  height: 40px;
  border-radius: 20px;
  border-width: 6px;
  border-color: #f7f7f7;
}
.horizontal-slider .example-thumb {
  top: -5px;
  width: 50px;
  outline: none;
  height: 50px;
  margin-left: -18px;
  background: #fdc02e;
  border: 8px solid white;
  line-height: 38px;
}

.custom-rotate-15 {
  transform: rotate(15deg);
}

.custom-rotate-minus-15 {
  transform: rotate(-15deg);
}

.custom-rotate-12 {
  transform: rotate(12deg);
}

.custom-rotate-8 {
  transform: rotate(8deg);
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  background: #f7f7f7; /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  top: 0;
  height: 40px;
  border-radius: 20px;
  border-width: 6px;
  border-color: #f7f7f7;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  top: 10px;
  width: 50px;
  outline: none;
  margin-top: -10px;
  height: 50px;
  background: #fdc02e;
  border: 8px solid white;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  top: 10px;
  width: 50px;
  outline: none;
  height: 50px;
  background: #fdc02e;
  border: 8px solid white;
  line-height: 38px;
  border-radius: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

input[type="range"] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(#fdc02e, #fdc02e) 0 / var(--sx) 100% no-repeat,
    rgba(239, 239, 239, 0);
  border-radius: 40px;
  height: 30px;
}

input[type="range"]:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#fdc02e, #fdc02e) 0 / var(--sx) 100% no-repeat,
    rgba(229, 229, 229, 0);
  border-radius: 40px;
  height: 30px;
}

input[type="range"]:active::-webkit-slider-runnable-track {
  background: linear-gradient(#fdc02e, #fdc02e) 0 / var(--sx) 100% no-repeat,
    rgba(245, 245, 245, 0);
  border-radius: 40px;
  height: 30px;
}

.Toastify__toast--success {
  background: #44c196 !important;
}
.Toastify__toast--warning {
  background: #eb8a19 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #eb1919 !important;
}
